.AddContactMensage p{
  font-size: 1.5rem;
  font-weight: bold;
  color: green;
}

.AddContactButton{
  width: 40px;
  text-align: center;
  align-items: center;
  margin-left: 10px;
}