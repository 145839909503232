.paginatio{
  margin-top: 2rem;
  display: flex;
  list-style: none;
}

.paginatio li + li {
  margin-left: 1rem;
}

.paginatio__item--active{
  background: none !important;
  font-weight: bold !important;
  color: black !important;
  border: 1.5px solid #FCC107 !important;
}

.paginatio__item--active:focus{
  outline: none !important;
}


.Pagination__pages button{
  border: none;
}
.Pagination__before button{
  border: none;
}
.Pagination__next button{
  border: none;
}