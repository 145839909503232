.ContactList__PageLimit{
  list-style: none;
}

.ContactList__PageLimit li button{
  padding: 5px 20px;
  border-radius: .5rem;
  border: none;
}

.ContactList__PageLimit li + li {
  margin-left: 1rem;

}

.width{
  min-width: 150px;
}
.width__2{
  min-width: 152px;
}

.Pix{
  margin-left: -15px;
}

.ContactList__Container__box{
  display: flex;
  justify-content: space-between;
}

.ContactList__Container__filter{
  padding: 0 1.7rem;
}

.dadada{
  margin-left: -50px;
}