@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

body{
  font-family: 'Ubuntu', sans-serif !important;
  background-color: #eee8e4 !important;
}

.contact-img{
  width: 150px;
  height: 150px;
  border-radius: 50%;
}