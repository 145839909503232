.EditContactObservation{
  margin-right: 1rem;
}

.EditObservationContainer{
  padding: 1rem !important;
}

.EditContactWidth{
  width: 80% !important;
}